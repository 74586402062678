import React from 'react';
import { useInView } from 'react-intersection-observer';

const Footer = () => {
  const { ref: footerRef, inView: footerInView } = useInView({ triggerOnce: true });

  return (
    <div
      ref={footerRef}
      className={`py-4 text-center bg-primary text-white ${footerInView ? 'opacity-100 translate-y-0' : 'opacity-0 translate-y-10'}`}
      style={{ transition: 'opacity 0.5s, transform 0.5s' }}
    >
      &copy; 2023 all rights reserved
    </div>
  );
};

export default Footer;
