import React from "react";
import AboutImg from "../Assets/Images/AboutImg.jpg";
import { useInView } from "react-intersection-observer";

const Contact = () => {
  const { ref: contactRef, inView: contactInView } = useInView({ triggerOnce: true });
  const { ref: emailRef, inView: emailInView } = useInView({ triggerOnce: true });
  const { ref: phoneRef, inView: phoneInView } = useInView({ triggerOnce: true });

  return (
    <section className="bg-secondary px-5 py-14 md:py-32 font-poppins relative z-10" id="contact">
      <div
        ref={contactRef}
        className={`text-center bg-gradient-to-r from-cyan-600 to-cyan-800 rounded-lg md:w-[80%] mx-auto p-2 md:p-4 text-white ${contactInView ? 'opacity-100 translate-y-0' : 'opacity-0 translate-y-10'}`}
        style={{ transition: 'opacity 0.5s, transform 0.5s' }}
      >
        <h2 className="text-4xl font-bold mb-5 border-b-[5px] w-[200px] mx-auto border-indigo-600 pb-2">
          Contact Me
        </h2>
        <p>
          I am currently open for a fulltime Frontend Developer role. If you
          want to discuss about that feel free to email me or call me.
        </p>
        <div className="md:flex md:items-center md:justify-center md:gap-3 mt-4">
          <div
            ref={emailRef}
            className={`flex-1 w-auto bg-primary rounded-xl flex items-center justify-center py-2 px-2 md:min-h-[80px] ${emailInView ? 'opacity-100 translate-x-0' : 'opacity-0 translate-x-10'}`}
            style={{ transition: 'opacity 0.5s, transform 0.5s' }}
          >
            <p>
              <span className="font-bold mr-2">Email:</span>
            </p>
            <img
              src={AboutImg}
              alt="Contact"
              className="w-12 h-14 rounded-full mr-2"
            />
            <a href="mailto:bludvistd@gmail.com">bludvistd@gmail.com</a>
          </div>
          <div
            ref={phoneRef}
            className={`mt-2 md:mt-0 flex-1 w-auto bg-primary rounded-xl flex items-center justify-center py-2 px-2 md:min-h-[80px] ${phoneInView ? 'opacity-100 translate-x-0' : 'opacity-0 translate-x-10'}`}
            style={{ transition: 'opacity 0.5s, transform 0.5s' }}
          >
            <p className="py-2">
              <span className="font-bold">Phone: </span>9860466590
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Contact;
