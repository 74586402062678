// src/LoadingScreen.jsx
import React, { useState, useEffect } from 'react';

const LoadingScreen = () => {
  const [progress, setProgress] = useState(1);

  useEffect(() => {
    const interval = setInterval(() => {
      setProgress(prevProgress => {
        const nextProgress = prevProgress + 1;
        return nextProgress > 100 ? 100 : nextProgress;
      });
    }, 30); // Adjust the interval time as needed

    return () => clearInterval(interval);
  }, []);

  return (
    <div className="flex items-center justify-center min-h-screen bg-purple text-purple">
      <div className="flex flex-col items-center">
        <div className="loader ease-linear rounded-full border-8 border-purple-800 h-24 w-24 mb-4"></div>
        <p className="text-lgg font-semibold">LOADING... {progress}%</p>
      </div>
    </div>
  );
};

export default LoadingScreen;
