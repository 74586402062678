import React from "react";
import { useInView } from "react-intersection-observer";

const Projects = () => {
  const { ref: projectsInfoRef, inView: projectsInfoInView } = useInView({ triggerOnce: true });

  return (
    <div>
      <section className="relative bg-primary text-white px-5 md:py-32 py-14 font-poppins z-10" id="projects">
        {/* Background designs */}
        <div className="absolute right-0 top-0 h-full w-1/2 overflow-hidden pointer-events-none">
          <div className={`absolute right-10 top-10 w-24 h-24 bg-gradient-to-r from-yellow-400 to-yellow-600 rounded-full opacity-75 transition-opacity duration-500 ${projectsInfoInView ? 'opacity-100' : 'opacity-0'}`}></div>
          <div className={`absolute right-20 top-40 w-16 h-16 bg-gradient-to-r from-blue-400 to-blue-600 rounded-full opacity-75 transition-opacity duration-500 ${projectsInfoInView ? 'opacity-100' : 'opacity-0'}`}></div>
          <div className={`absolute right-10 bottom-20 w-32 h-32 bg-gradient-to-r from-pink-400 to-pink-600 rounded-full opacity-75 transition-opacity duration-500 ${projectsInfoInView ? 'opacity-100' : 'opacity-0'}`}></div>
          <svg className={`absolute right-20 bottom-10 w-16 h-16 opacity-75 transition-opacity duration-500 ${projectsInfoInView ? 'opacity-100' : 'opacity-0'}`} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2">
            <path strokeLinecap="round" strokeLinejoin="round" d="M12 3l1.88 3.78L18 8l-3 2.92.71 4.22L12 14.5l-3.71 1.64.71-4.22L6 8l4.12-.22L12 3z" />
          </svg>
        </div>

        <div className="relative container mx-auto grid md:grid-cols-2 items-center md:justify-between z-10">
          <div
            ref={projectsInfoRef}
            className={`about-info mb-5 ${projectsInfoInView ? 'opacity-100 translate-y-0' : 'opacity-0 translate-y-10'}`}
            style={{ transition: 'opacity 0.5s, transform 0.5s' }}
          >
            <h2 className="text-4xl font-bold mb-5 border-b-[5px] w-[180px] border-indigo-600 pb-2">
              Projects
            </h2>
            <p className="pb-5">
              These are some of my best projects. I have built these with React,
              Redux and Tailwind CSS. Check them out.
            </p>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Projects;
