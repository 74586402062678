import React from "react";
import AboutImg from "../Assets/Images/AboutImg.jpg";
import { useInView } from "react-intersection-observer";

const About = () => {
  const { ref: aboutInfoRef, inView: aboutInfoInView } = useInView({ triggerOnce: true });
  const { ref: aboutImgRef, inView: aboutImgInView } = useInView({ triggerOnce: true });

  return (
    <div className="relative font-poppins">
      <section className="bg-secondary text-white px-5 py-8 relative z-10" id="about">
        <div className="container mx-auto grid md:grid-cols-2 items-center justify-center md:justify-between">
          <div
            ref={aboutInfoRef}
            className={`bg-gradient-to-br from-fuchsia-700 to-fuchsia-950 text-white p-4 mr-2 rounded-lg ${aboutInfoInView ? 'opacity-100 translate-x-0' : 'opacity-0 translate-x-10'}`}
            style={{ transition: 'opacity 0.5s, transform 0.5s' }}
          >
            <h2 className="text-4xl font-bold mb-5 border-b-[5px] w-[180px] border-indigo-600 pb-2">
              About Me
            </h2>
            <p className="pb-5">
              Hi, My Name Is Pratham Dahal. I am a Frontend Developer. I build
              beautiful websites with React and Tailwind CSS.
            </p>
            <p className="pb-5">
              I am proficient in Frontend skills like React.js, Redux, Redux
              Tool Kit, Axios, and Tailwind CSS.
            </p>
            <p>
              In my spare time I watch YouTube videos and write the interesting
              ideas I gather from those videos. I think about programming theory
              and building various projects to expand my portfolio.
            </p>
          </div>

          <div
            ref={aboutImgRef}
            className={`about-img ${aboutImgInView ? 'opacity-100 translate-x-0' : 'opacity-0 translate-x-10'} mt-1`}
            style={{ transition: 'opacity 0.5s, transform 0.5s' }}
          >
            <img
              src={AboutImg}
              alt="coding illustration"
              className="lg:w-[80%] md:ml-auto"
            />
          </div>
        </div>
      </section>
    </div>
  );
};

export default About;
