import React from 'react';
import { AiOutlineTwitter, AiOutlineFacebook, AiOutlineInstagram } from "react-icons/ai";
import { useInView } from 'react-intersection-observer';
import 'animate.css';
import '../Components/Hero.css'; 

const Hero = () => {
  const { ref: heroInfoRef, inView: heroInfoInView } = useInView({ triggerOnce: true });
  const { ref: socialIconsRef, inView: socialIconsInView } = useInView({ triggerOnce: true });
  const { ref: buttonRef, inView: buttonInView } = useInView({ triggerOnce: true });
  const { ref: heroImgRef, inView: heroImgInView } = useInView({ triggerOnce: true });

  return (
    <div>
      <section className="bg-primary px-5 text-white py-14">
        <div className="container mx-auto grid md:grid-cols-2 items-center font-poppins justify-center md:justify-between">
          <div
            ref={heroInfoRef}
            className={`hero-info pb-5 md:pb-0 ${heroInfoInView ? 'animate__animated animate__fadeInLeft' : ''}`}
          >
            <h1 className="text-4xl lg:text-6xl">
              Hi, <br />I am <span className="text-accent">a</span><br />
              <span className="color-changing-text">Frontend Developer</span>
            </h1>

            <p className="py-5">
              I am proficient in JavaScript, React.js and Tailwind CSS
            </p>

            <div
              ref={socialIconsRef}
              className={`flex py-5 ${socialIconsInView ? 'animate__animated animate__fadeInUp animate__delay-1s' : ''}`}
            >
              <a
                href="https://twitter.com"
                className="pr-4 inline-block text-accent hover:text-white"
              >
                <AiOutlineTwitter size={40} />
              </a>
              <a
                href="https://www.instagram.com"
                className="pr-4 inline-block text-accent hover:text-white"
              >
                <AiOutlineInstagram size={40} />
              </a>
              <a
                href="https://www.facebook.com"
                className="pr-4 inline-block text-accent hover:text-white"
              >
                <AiOutlineFacebook size={40} />
              </a>
            </div>

            <a
              ref={buttonRef}
              href="/#projects"
              className={`btn bg-accent border-2 border-[#7477FF] text-white px-6 py-3 hover:bg-transparent ${buttonInView ? 'animate__animated animate__fadeInUp animate__delay-2s' : ''}`}
            >
              See Projects
            </a>
          </div>

          <div
            ref={heroImgRef}
            className={`hero-img ${heroImgInView ? 'animate__animated animate__fadeInRight' : ''}`}
          >
            <img
              src="https://cdn.dribbble.com/users/2600681/screenshots/5890373/workspace__2__4x.png"
              alt="coding illustration"
              className="lgw-[80%] ml-auto"
            />
          </div>
        </div>
      </section>
    </div>
  );
};

export default Hero;
